// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 600px) {
    .detail-share-links-wrapper span {
        width: 100%;
    }

    .order-buttons-wrapper .wrapper {

        > * {
            flex-grow: 1;

            .return-button {
                width: 100%;
            }
        }
    }

    .cart-promo-code {
        width: 100%;
    }

    .cart-header {
        gap: 10px;

        .logo {
            flex-shrink: 1;
            max-width: 40%;

            img {
                height: auto;
                width: auto;
                max-height: 40px;
                max-width: 100%;
            }
        }

        .cart-back-link {
            white-space: nowrap;
            flex-shrink: 0;
        }
    }

    .check-person {
        gap: 10px;
    }

    form .row-wrapper.address-wrapper,
    form .row-wrapper.city-wrapper {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .stars-rating.average-rating {
        top: -2px;
    }

    .gs-tabs {

        nav {
            flex-direction: column;

            button {
                width: 100%;
            }
        }
    }

    .user-custom-content {

        iframe {
            max-width: 100%;
        }
    }

    .privacy-box {
        padding: 10px;
        gap: 10px;
    }

    .privacy-box__header {
        gap: 10px;

        img {
            height: 20px;
        }

        strong {
            font-size: 14px;
        }
    }

    .privacy-box__actions {
        flex-wrap: wrap;

        button {
            min-height: 35px;
        }
    }

    .privacy-box__actions button.privacy-accept-all {
        width: 100%;
        order: -1;
        flex: 0 0 auto;
        min-height: 45px;
    }

    .pagination {
        gap: 4px;

        a {
            width: 30px;
            height: 30px;
        }
    }

    .address-select-list {
        width: ~"calc(100vw - 42px)";
        overflow-x: auto;
        flex-wrap: nowrap;
        padding-top: 10px;
    }

    .cart-success .cart-popup-row-wrapper {
        flex-direction: column;
    }

    .tippy-box .tippy-content {
        padding: 10px;
    }

    .infobox {
        padding: 10px;

        > * {
            width: 100%;
        }

        &:before {
            font-size: 80px;
        }
    }

    #breadcrumb {
        display: flex;
        margin-left: -10px;
        width: ~"calc(100% + 20px)";
        justify-content: flex-start;
        position: relative;
        padding-bottom: 5px;

        .breadcrumb-end {
            display: block;
            position: absolute;
            top: 0;
            top: 0;
            height: 100%;
            width: 20px;
            pointer-events: none;

            &:nth-of-type(1) {
                left: -7px;
                background-image: linear-gradient(to left, rgba(46, 18, 4, 0), rgba(46, 18, 4, 1), rgba(46, 18, 4, 1));
            }

            &:nth-of-type(2) {
                right: -7px;
                background-image: linear-gradient(to right, rgba(46, 18, 4, 0), rgba(46, 18, 4, 1), rgba(46, 18, 4, 1));
            }
        }

        ul {
            flex-wrap: nowrap;
            overflow-y: auto;
            justify-content: flex-start;
            flex-direction: row-reverse;
            padding-bottom: 5px;
        }

        li {
            white-space: nowrap;

            &:last-of-type {
                padding-right: 10px;
            }

            &:first-of-type {
                padding-left: 10px;
            }

            &:nth-of-type(1) {
                order: 10;
            }

            &:nth-of-type(2) {
                order: 9;
            }

            &:nth-of-type(3) {
                order: 8;
            }

            &:nth-of-type(4) {
                order: 7;
            }

            &:nth-of-type(5) {
                order: 6;
            }
        }
    }

    .captcha-wrapper {

        > div {
            width: 268px !important;
            overflow: hidden;
            
            iframe {
                transform-origin: left top;
                transform: scale(.87);
            }
        }
    }

    .address-select-list li:not(.no-addresses) {
        max-width: 100%;
        min-width: 75%;
    }

    #detail-images .swiper-button-prev {
        left: 0;
    }

    #detail-images .swiper-button-next {
        right: 0;
    }

    #gsGalleryContainer > a {
        width: 100%;
    }

    .navigation-wrapper {
        padding-top: 0;
        border-top: 0;
    }

    .dropdown-picker,
    .filter-trigger,
    .dropdown-button {
        min-width: 0;
        white-space: nowrap;
    }

    .filter-trigger {
        width: 100%;
    }

    .dropdown-button {
        width: 100%;
        font-size: 12px;
    }

    .filter-trigger {
        font-size: 12px;
    }

    .navigation-wrapper {
        gap: 20px 10px;
    }

    .dropdown-picker:before,
    .filter-trigger:before {
        left: 2px;
    }

    .filter-trigger,
    .dropdown-button {
        padding: 0 30px 0 10px;

        svg {
            right: 5px;
            transform: translateY(-50%) scale(.8);
        }

        &:after {
            width: 30px;
        }
    }

    .dropdown-button {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dropdown-picker {

        &[data-dropdown-type="sort"] {
            width: calc(60% - 5px);
            
            .dropdown-listing {
                left: 0;
            }
        }

        &[data-dropdown-type="pageSize"] {
            width: calc(40% - 5px);

            .dropdown-listing {
                left: auto;
                right: 0;
            }
        }
    }

    .goshop-modal header {
        font-size: 16px;
        padding-right: 50px;
        line-height: 1.2;
        height: 50px;

        &:before {
            width: 40px;
            height: 40px;
        }
    }

    .goshop-modal header ~ .goshop-modal-close,
    .availability-modal-wrapper .goshop-modal-close {
        font-size: 14px;
        width: 50px;
        height: 50px;
    }

    .cart-success section {
        padding: 10px;
        text-align: center;
        
        figure {
            width: 100%;
            height: 80px;
            margin: 0 0 10px;
        }

        > div {
            width: 100%;

            > strong {
                font-size: 16px;
            }
        }
    }

    .cart-success footer {
        padding: 0 10px 10px;
    }

    .cart-success footer,
    .shop-buttons-wrapper {
        gap: 10px;

        > * {
            width: 100%;
            text-align: center;

            &:nth-child(2) {
                order: -1;
            }
        }
    }

    .availability-modal-wrapper .wrapper {
        padding: 10px;
        justify-content: center;
        gap: 10px;

        > p {
            text-align: center;
        }

        > .availability-product {
            justify-content: center;
        }
    }

    .availability-modal-wrapper .availability-product {
        gap: 10px;
        padding: 10px 0;
    }

    .availability-modal-wrapper .input-wrapper {
        flex-wrap: wrap;

        > * {
            width: 100%;
        }
    }

    .check-person {

        > label {
            width: 100%;
        }

        .check-simple:first-of-type {
            margin-left: 0;
        }
    }

    .login-form > .signup-wrapper {
        width: 100%;
        padding-right: 0;
        border-right: 0;
    }

    .login-form > .signup-wrapper:nth-child(2) {
        padding-left: 0;
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid @border-color;
    }

    .cms-subarticles-wrapper {
        columns: 1 !important;
    }

    .user-orders-list .order-header .buttons-wrapper {
        position: static;
        float: left;
        clear: both;
        margin-top: 10px;
        width: 100%;
        flex-direction: row;

        a {
            padding: 5px;
        }
    }

    .order-detailed-info .item-box-large th:nth-child(2),
    .order-detailed-info .item-box-large td:nth-child(2) {
        display: none;
    }

    .login-form.logged .logged-header h2 {
        font-size: 18px;
    }

    .product-images {
        width: 100%;
    }

    .product-images .size-full {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
    }

    .detail-amount {
        width: 100%;
        margin: 0 0 10px;
    }

    .thread-messages .message {
        margin-bottom: 20px;
    }

    .thread-messages .message header {
        flex-wrap: wrap;
        gap: 5px;
    }

    .thread-messages .message header .message-name {
        font-size: 14px;
    }

    .message-time {
        width: 100%;
        margin-top: -5px;
    }

    .thread-messages .message.client-response header .message-time {
        text-align: right;
    }

    .payment-channels-modal .goshop-modal .wrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .pills-group:before,
    .remove-all-filters {
        font-size: 11px;
    }

    .single-pill {
        font-size: 11px;
        padding: 2px 5px;
    }

    #pills-container:not(:empty) {
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    .remove-all-filters {
        padding: 2px 0;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    .shop-header-container {
        height: 120px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .logo img {
        height: 50px;
    }

    .header-tools-wrapper {
        width: 100%;
        justify-content: space-evenly;
    }

    .shop-header-main-wrapper {
        width: 100%;
    }

    .main-banner b {
        bottom: 0;
        font-size: 18px;
        min-width: 0;
        padding: 5px 10px;
    }

    .swiper-button-next {
        right: 10px;
    }

    .swiper-button-prev {
        left: 10px;
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 28px;
        height: 28px;
        margin-top: -14px;
    }

    .checkout-tab span, .checkout-tab a {
        font-size: 13px;
        padding: 6px 12px 4px;
    }

    .product-price b {
        font-size: 16px;
    }

    .banners-grid {
        grid-template-rows: repeat(4, 15vh);
        width: 100%;
    }

    .banners-grid a.one {
        grid-column: 1 / 3;
        grid-row: 1;
    }

    .banners-grid a.two {
        grid-column: 3 / 5;
        grid-row: 1;
    }

    .banners-grid a.three {
        grid-row: 2;
        grid-column: 1 / -1;
    }

    .banners-grid a.four {
        grid-row: 3;
        grid-column: 1 / -1;
    }

    .banners-grid a.five {
        grid-row: 4;
        grid-column: 1 / -1;
    }

    .bottom-static-banner {
        grid-template-columns: minmax(0, 1fr);
        gap: 10px;
    }

    #newsletter {
        flex-direction: column;
        padding: 20px 0;
        gap: 10px;
    }

    .footer-bottom-bar {
        flex-direction: column;
        gap: 5px;
    }

    .dropdown-picker.show-dropdown {
        z-index: 12;
    }

    .footer-bottom-bar a {
        padding: 9px 0;
    }

    .footer-bottom-bar {
        padding: 20px 0 80px;
    }

    .product {
        padding: 10px 10px 0;
    }

    .product-add {
        display: none;
    }

    .detail-header {
        margin-bottom: 10px;
    }

    .detail-availability {
        margin-bottom: 10px;
    }

    #detail-traits {
        display: flex;
        flex-direction: column;
        gap: 10px;

        tbody {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        tr {
            display: flex;
            flex-direction: column;
        }

        td {
            width: 100%;
        }
    }

    #detail-tabs nav button {
        padding: 10px;
    }

    .detail-left-column {
        width: 100%;
    }

    .product-images .size-full {
        aspect-ratio: 400 / 533;
    }

    .detail-comments-header span {
        width: 100%;
        margin: 0 0 5px;
    }

    .comment cite {
        padding: 10px;
    }

    .footer-item > strong {
        line-height: 26px;
    }

    .footer-shortcut a {
        min-height: 44px;
    }
}